<!-- 员工管理 -->
<template>
    <div style="background-color:#fff">
        <div style="width:920px;margin-left:auto;margin-right: auto;min-height: 700px;">
            <div class="middle p-40 text-left">
                <h3 class="text-center">企业员工管理</h3>
                <div class="m-t-15" style="position:relative;">
                    <div class="text-right">
                        <el-button size="mini" type="primary" plain @click="$oucy.go('/enter/StaffSave')">绑定员工</el-button>
                    </div>
                </div>
                <div v-if="ReturnsObject.content" class="m-t-50">
                    <div class="m-t-20 box3s">
                        <div v-for="(v,i) in ReturnsObject.content" class="box3" :key="i">
                            <div class="df-ai-c">
                                <div><img :src="$oucy.ossUrl + v.ltUserDetailFull.detailAvatar" class="employeePic" v-if="v.ltUserDetailFull && v.ltUserDetailFull.detailAvatar"></div>
                                <div class="m-l-10">
                                    <div class="c-1">{{v.userDcodeUsername}}<span class="m-l-10 c-2 f14">{{v.isAdmin?'管理员':'员工'}}</span></div>
                                    <div class="c-2 f14 m-t-10">{{v.userPhoneNumber}}</div>
                                </div>
                            </div>
                            <div>
                                <div class="f14 bold">管理权限</div>
                                <div v-if="!v.isAdmin" class="f14 c-2">
                                    <el-button v-for="(vv,ii) of v.enterpriseMenus" :key="ii" type="text" size="mini" class="f14 c-2 m-r-10 m-t-10">{{vv.enterpriseMenuName}}</el-button>
                                </div>
                                <!-- <div class="f14 c-2" v-else>所有权限</div> -->
                            </div>
                            <div class="text-right">
                                <el-button size="mini" type="" @click="delConfirm(v,i,'del')">解除绑定</el-button>
                                <el-button size="mini" type="primary" @click="$oucy.go('/enter/StaffSave?id='+v.id)">编辑员工</el-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center c-2 p-40">没有员工</div>
            </div>
            <div class="pagination">
                <el-pagination v-if="ReturnsObject.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="ReturnsObject.size" :total="ReturnsObject.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import { cardEmployee, enterpriseinfo, ltIdentity, china, cardCategory, cardPerson, cardEnterprise, furnitureGlobalBrand } from "@/service/index.js"
import { localSet, localGet, localDel } from "@/store/store";
import oucy from "@/util/oucyUtil";

export default {
    name: "Card",
    data() {
        return {

            // 请求对象            
            QueryFurnitureLibrary: {
                limit: 10,
                start: 0,
                enterpriseId:null,
                // enterpriseApplyEnum: 1, //员工分类
            },
            // 返回对象
            ReturnsObject: {},
            id: null,
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.id = enterprise.id
        } else {
            this.$oucy.replace(`/acc/enterprise`)
        }
        this.QueryFurnitureLibrary.enterpriseId = this.id
        this.getList()
    },
    methods: {
        // 员工员工  员工回收站
        handleClick() {
            this.QueryFurnitureLibrary.start = 0
            this.ReturnsObject = {}
            this.getList()
        },
        // 切换状态回调
        SetCardStatus(v) {
            this.QueryFurnitureLibrary.start = 0
            this.QueryFurnitureLibrary.employeeStatus = v.id
            this.getList()
        },

        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.getList();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.getList();
        },

        // 查列表 
        getList() {

            this.getEmployee()
        },

        // 查询列表
        getEmployee() {
            enterpriseinfo.getEmployee(this.QueryFurnitureLibrary).then(res => {
                this.ReturnsObject = res || {}
            })
        },
        // 删除员工
        removeEmployee(v, i) {
            enterpriseinfo.removeEmployee({ userAuthId: v.ltUserId, enterpriseId: this.id }).then(res => {
                this.$message('解除成功')
                this.delContent(i)
            })
        },
        delConfirm(v, i, type) {
            this.$confirm(`确定要解除绑定 ${v.userDcodeUsername} 吗？`, '解除绑定', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                // type: 'warning'
            }).then(() => {
                this.removeEmployee(v, i)
            }).catch(() => {});
        },

        // 删除
        delContent(i) {
            this.ReturnsObject.content.splice(i, 1)
            if (this.ReturnsObject.content.length == 0) {
                this.getList()
            }
        },
    }
}
</script>
<style scoped lang="less">
.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.left_nav .el-button--text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin: 30px 0 0 30px;
    color: #111111;
}

.left_nav .el-button--text:focus {
    color: #2090FF;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.el-collapse {
    border: none;
}

.el-collapse-item__header {
    border: none;
}

.el-collapse-item__wrap {
    margin-top: 20px;
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    margin-bottom: 20px;
    margin-left: -170px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
    margin-top: 10px;
    text-align: left;
}

.top_sort .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}



.enterprise_card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
}

.enterprise_card span {
    width: 160px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    float: left;
    display: flex;
    padding: 25px 0 0 10px;
}

.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    margin: 15px auto;
    width: 100%;
    height: 100%;
}

.enterprise_card .info {
    width: 80px;
    /*height: 43px;*/
    background: #F8F8F9;
    border-radius: 2px;
    /*margin-top: 30px;*/
    margin-left: 6px;
}

.enterprise_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    /*margin-top: -20px;*/
}

.enterprise_card .info p+p {
    /*padding-top: 10px;*/
}

.personal_card .info {
    width: 57px;

    background: #F8F8F9;
    border-radius: 2px;

}

.personal_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    line-height: 20px;
    text-align: center;
    /*margin-top: -20px;*/
}

.staff_info {
    width: 88px;
}

.staff_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: center;
}

.contact {
    width: 233px;
    /*height: 50px;*/
    background-color: rgb(243, 249, 255);
    margin: 6px auto;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.contact .el-button--text {
    width: 70px;
    border: 1px rgb(32, 144, 255) solid;
    float: right;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.pagination {
    margin: 30px auto 120px;
}

/*/deep/ .btn-next {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .btn-prev {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .el-pager {
    position: relative;
    top: 9px;
}

/deep/ .number {
    background: #FFFFFF !important;
}

/deep/ .active {
    background: #2090ff !important;
}*/

.bus_recomm {
    width: 100%;
}

.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 30px;*/
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    text-align: left;
    margin-bottom: 20px;
    /*margin-left: -170px;*/
    padding-left: 10px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
    /*margin-top: 10px;*/
    text-align: left;
}

.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}



.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    width: 73px;
    height: 47px;
    margin-top: 15px;
}




.middle_card_star {
    float: left;
    display: flex;
    margin-left: 10px;
}

.info {
    width: 70px;
    /*height: 43px;*/
    background-color: rgb(248, 248, 249);
    padding: 2px;
    margin: 15px auto 0px;
}

.info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 6px;
}

.goods_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: left;
    margin-left: 7px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}


.middle ul li:nth-child(3n) {
    margin-right: 0px;
}



.items {
    margin-left: 10px;
}

.item {
    padding: 20px;
    background: #fff;
    margin-top: 10px;
    display: flex;
}

.logo {
    width: 168px;
    height: 168px;
    background: #FDFDFD;
    border: 1px solid #F3F3F3;
    border-radius: 1px;
}

.dot {
    padding-left: 30px;
    flex: 1;
}

.itemTop {
    align-items: flex-start;
}

.icon3 {}

.icon4 {
    margin-right: 5px;
}

.cover {
    width: 80px;
    margin-left: 10px;
}

.myauth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    /*margin-left: 10px;*/
    /*margin-top: 10px;*/
    text-align: left;
}

.mybutton {
    margin: 0 10px 10px 0;
    background: #F6F7FB;
    border: none;
    color: #606266;
    padding: 6px 12px;
    border-radius: 4px;
}

.text-left {
    text-align: left;

}

.myauth {
    display: flex;
    text-align: left;
}

.myauth .myauthLift {
    font-weight: bold;
    display: block;
    width: 90px;
}

.myauth .text-left {
    flex: 1;
}

.selectedChina {
    color: #fff;
    background-color: rgb(32, 144, 255);
}

.el-radio-group {
    text-align: left;
}

.middle {
    background: #fff;
    // margin-top: 20px;
    margin-left: 20px;
}

.enterpriseAvata {
    width: 90px;
    height: 90px;
}

.box1 {
    margin-right: 20px;
    padding: 20px;
    flex: 1;
    background: #F8F8F9;
}

.cardCode {
    width: 120px;
    height: 120px;
}

.box2s {
    display: flex;
    flex-wrap: wrap;
}

.box2 {
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    background: #FAFAFA;
    background-color: #ffffff;
    padding-bottom: 15px;
    border: 1px solid #EAEAEA;
    text-align: center;
    width: 218px;

    &:hover {

        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        background: #FFFFFF;
    }

    .box img {
        width: 100%;
        // height: 163px;
    }
}

.box3s {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.box3 {
    // display: flex;
    // justify-content: space-between;
    border: 1px solid #EAEAEA;
    width: 400px;
    padding: 15px;
    // margin-right: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    box-sizing:border-box;
}

.employeePic {
    width: 80px;
    height: 80px;
}

.html /deep/ img {
    max-width: 100%;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    min-height: 700px;
    text-align: center;
}
</style>